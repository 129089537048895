import React from 'react';
import cx from 'classnames';
import styles from './TextArea.module.scss';

const TextArea = ({
  label,
  onChange,
  required = false,
  error = false,
  value,
  ...props
}) => {
  const { defaultValue } = props;
  const [hasValue, setHasValue] = React.useState(defaultValue);
  const [customValue] = React.useState(value);

  const handleChange = React.useCallback(
    (event) => {
      setHasValue(event.currentTarget.value);
      onChange(event);
    },
    [onChange, value],
  );

  const className = cx({
    [styles.root]: true,
    [styles.error]: error,
    [styles.hasValue]: hasValue,
  });

  return (
    <div className={className}>
      <label>
        <span className={styles.label}>
          {label} {required && <span className={styles.required}>*</span>}
        </span>
        <textarea
          className={styles.input}
          title={label}
          onChange={handleChange}
          defaultValue={customValue}
          {...props}
        />
      </label>
    </div>
  );
};

export default TextArea;
