import React from 'react';
import styles from './ContactModal.module.scss';
import FormComponent from '../../generic/FormComponent/FormComponent';
import TextInput from '../../generic/TextInput/TextInput';
import TextArea from '../../generic/TextArea/TextArea';
import { motion } from 'framer-motion';
import { Link } from 'gatsby'
import { useForm } from 'react-hook-form';
import Modal from '../../generic/Modal/Modal';
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';

const ContactModal = ({lesson, ...props }) => {
  const [submitted, setSubmitted] = React.useState(false);
  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    control,
    formState: { isSubmitting }
  } = useForm();

  const submitForm = async formData => {
    try {
      await fetch('https://formspree.io/xnqbnebo', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(formData),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      });
      setSubmitted(true);
      reset();
    } catch (error) {
      setError(
        'submit',
        "submitError",
        `Oops! There seems to be an issue! ${error.message}`
      )
    }
  }

  return (
    <Modal {...props}>
      <div className={styles.poster} />
      <AnimatePresence>
        <AnimateSharedLayout>
          <div className={styles.modal}>
            {!submitted ? (
              <form className={styles.form} onSubmit={handleSubmit(submitForm)}>
                <h2 className={styles.title}>Contact</h2>
                <div className={styles.formGroup}>
                  <div className={styles.input}>
                    <motion.div layout>
                      <FormComponent
                        control={control}
                        name="firstname"
                        friendlyName='Voornaam'
                        rules={{
                          required: true
                        }}
                        defaultValue={''}
                        render={(props) => (
                          <TextInput
                            wide
                            required
                            label="Voornaam"
                            errors={errors.firstname}
                            {...props}
                          />

                        )}
                      />
                    </motion.div>
                    <motion.div layout>
                      {errors.firstname && (
                        <p>{errors.firstname.message}</p>
                      )}
                    </motion.div>
                  </div>
                  <div className={styles.input}>
                    <motion.div layout>
                      <FormComponent
                        control={control}
                        name="lastname"
                        friendlyName='Achternaam'
                        rules={{
                          required: true
                        }}
                        defaultValue={''}
                        render={(props) => (
                          <TextInput
                            wide
                            required
                            label="Achternaam"
                            errors={errors.lastname}
                            {...props}
                          />

                        )}
                      />
                    </motion.div>
                    <motion.div layout>
                      {errors.lastname && (
                        <p>{errors.lastname.message}</p>
                      )}
                    </motion.div>
                  </div>
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.input}>
                    <motion.div layout>
                      <FormComponent
                        control={control}
                        name="email"
                        friendlyName={'Email'}
                        rules={{
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        }}
                        defaultValue={''}
                        render={(props) => (
                          <TextInput
                            wide
                            required
                            error={errors.email}
                            label={'Email'}
                            {...props}
                          />
                        )
                        }
                      />
                    </motion.div>
                    <motion.div layout className={styles.error}>
                      {errors.email && (
                        <p>{errors.email.message}</p>
                      )}
                    </motion.div>
                  </div>

                  <div className={styles.input}>
                    <motion.div layout>
                      <FormComponent
                        control={control}
                        name="telephone"
                        friendlyName={'Telefoonnummer'}
                        rules={{
                          required: true,
                          pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                        }}
                        defaultValue={''}
                        render={(props) => (
                          <TextInput
                            wide
                            required
                            type="number"
                            error={errors.telephone}
                            label={'Telefoonnummer'}
                            {...props}
                          />
                        )
                        }
                      />
                    </motion.div>
                    <motion.div layout className={styles.error}>
                      {errors.telephone && (
                        <p>{errors.telephone.message}</p>
                      )}
                    </motion.div>
                  </div>
                </div>

                <div className={styles.input}>
                  <motion.div layout>
                    <FormComponent
                      control={control}
                      name="subject"
                      friendlyName='Onderwerp / Cursus Type'
                      rules={{
                        required: true
                      }}
                      defaultValue={''}
                      render={(props) => (
                        <TextInput
                          wide
                          required
                          label="Onderwerp / Cursus Type"
                          errors={errors.subject}
                          {...props}
                        />
                      )}
                    />
                  </motion.div>
                  <motion.div layout>
                    {errors.subject && (
                      <p>{errors.subject.message}</p>
                    )}
                  </motion.div>
                </div>

                <div className={styles.input}>
                  <motion.div layout>
                    <FormComponent
                      control={control}
                      name="description"
                      friendlyName="Beschrijving"
                      rules={{
                        required: true
                      }}
                      defaultValue={''}
                      render={(props) => (
                        <TextArea
                          wide
                          required
                          rows={8}
                          error={errors.message}
                          label="Beschrijving"
                          {...props}
                        />
                      )}
                    />
                  </motion.div>
                  <motion.div layout>
                    {errors.description && (
                      <p>{errors.description.message}</p>
                    )}
                  </motion.div>
                </div>

                <p>
                  Door uw gegevens hier boven in te vullen en door te gaan naar de volgende stap gaat u akkoord met de
                  <Link to={'/privacy-verklaring'}><span className="form__error"> privacy verklaring</span></Link>
                  .
                </p>

                <div className={styles.controls}>
                  <input className='button button--secondary' type="submit" value="Versturen" />
                </div>
              </form>
            ): (
              <div className="form__confirm">
                <p>Super! Je bericht is verstuurd. Er word zo snel mogelijk contact opgenomen</p>
                <button className="button" onClick={() => setSubmitted(false)}>Nog een bericht sturen?</button>
              </div>
            )}
          </div>
        </AnimateSharedLayout>
      </AnimatePresence>
    </Modal>
  )
}

export default ContactModal;
